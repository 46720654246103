import React, { createContext, useState } from "react";

const MyContext = createContext({});

const MyContextProvider = ({ children }) => {
  const [userData, setUserData] = useState(undefined);

  const [submitStatus, setSubmitStatus] = useState("idle");

  return (
    <MyContext.Provider
      value={{
        userData,
        setUserData,
        setSubmitStatus,
        isIdle: submitStatus === "idle",
        isSuccess: submitStatus === "success",
        isLoading: submitStatus === "loading",
        isError: submitStatus === "error",
      }}
    >
      {children}
    </MyContext.Provider>
  );
};

export { MyContext, MyContextProvider };
