import { React, useState, useEffect, useContext } from "react";
import { Formik, Form, Field } from "formik";
import DropdownSelect from "../DropdownSelect";
import Buttons from "../Buttons";
import ErrorMessage from "../ErrorMessage";
import * as Yup from "yup";
import MyInputField from "../MyInputField";
import { MyContext } from "../MyContext";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { CSSTransition } from "react-transition-group";

import loader from "./circle.svg";

dayjs.extend(customParseFormat);

const meet_type = [
  { id: "LOCAL", name: "Vor-Ort Termin" },
  // { id: "ONLINE_VIDEO", name: "Online Videomeeting" },
];

const salutation = [
  { id: 1, name: "keine Angabe" },
  { id: 2, name: "Frau" },
  { id: 3, name: "Herr" },
];

const duration = 300;

const DisplayingErrorMessagesSchema = Yup.object().shape(
  {
    firstname: Yup.string().required("Vorname ist ein Pflichtfeld"),
    lastname: Yup.string().required("Nachnname ist ein Pflichtfeld"),
    meet_type: Yup.string().required(
      "Bitte wählen Sie die Art des Termins aus!"
    ),
    location: Yup.string().required("Bitte wählen Sie ein Service-Center aus!"),
    concern: Yup.string().required("Bitte wählen Sie ein Anliegen aus!"),
    date: Yup.string().required("Bitte wählen Sie ein Datum aus!"),
    time: Yup.string().required("Bitte wählen Sie eine Uhrzeit aus!"),
    checked: Yup.bool().oneOf(
      [true],
      "Bitte stimmen Sie der Nutzung ihrer Email Adresse zu!"
    ),
    email: Yup.string()
      .email("Ungültige Email")
      .when("number", {
        is: (number) => !number || number.length === 0,
        then: Yup.string()
          .email()
          .required(
            "Bitte geben Sie eine Email Adresse oder Telefonnummer an!"
          ),
        otherwise: Yup.string(),
      }),
    number: Yup.string().when("email", {
      is: (email) => !email || email.length === 0,
      then: Yup.string().required(
        "Bitte geben Sie eine Telefonummer oder Email Adresse an!"
      ),
      otherwise: Yup.string(),
    }),
  },
  [["email", "number"]]
);

const Formular = () => {
  const [locations, setLocations] = useState([]);
  const [concerns, setConcerns] = useState([]);
  const [date, setDate] = useState(null);
  const [dates, setDates] = useState({});
  const [error, setError] = useState(false);
  const [loadingDates, setLoadingDates] = useState(false);
  const {
    userData,
    setUserData,
    setSubmitStatus,
    isError,
    isSuccess,
    isLoading,
    isIdle,
  } = useContext(MyContext);

  const [status, setStatus] = useState("idle");

  useEffect(() => {
    if (isSuccess) {
      window.scrollTo(0, 0);
    }
  }, [isSuccess]);

  useEffect(() => {
    setStatus("loading");
    Promise.all([loadConcerns(), loadLocations()]).then(
      () => setStatus("success"),
      () => setStatus("error")
    );
  }, []);

  const loadLocations = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/locations/`
      );
      if (!response.ok) {
        throw Error(response.statusText);
      } else {
        const data = await response.json();
        setLocations(data);
      }
    } catch (error) {
      setError(true);
    }
  };

  const loadConcerns = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/concerns/`
      );
      if (!response.ok) {
        throw Error(response.statusText);
      } else {
        const data = await response.json();
        setConcerns(data);
      }
    } catch (error) {
      setError(true);
    }
  };

  const loadDate = async (concern_id, location_id) => {
    if (concern_id && location_id) {
      setLoadingDates(true);
      try {
        const response = await fetch(
          `${
            process.env.REACT_APP_API_URL
          }/appointments/free/?concern=${concern_id}&location=${location_id}&date_end=${dayjs()
            .add(2, "week")
            .format("YYYY-MM-DD")}`
        );
        if (!response.ok) {
          throw Error(response.statusText);
        } else {
          const data = await response.json();

          // Umwandeln in Objekt mit key = datum und value = array von Zeiten
          let d = {};
          data.forEach((item) => {
            const [start_date, start_time] = item.start.split(" ");
            d[dayjs(start_date).format("DD.MM.YYYY")] = [
              ...(d[dayjs(start_date).format("DD.MM.YYYY")] ?? []),
              dayjs(start_time, "HH:mm:ss").format("HH:mm"),
            ];
          });
          setDates(d);
          setLoadingDates(false);
        }
      } catch (error) {
        setError(true);
        setLoadingDates(false);
      }
    }
  };

  // return (
  //   <div className="containerAussen">
  //     <header className="header">
  //       <img
  //         src={`${process.env.PUBLIC_URL ?? ""}/SW_witten.svg`}
  //         alt="logo"
  //       ></img>
  //     </header>
  //     <main>
  //       <div className="container" style={{ minHeight: "80vh" }}>
  //         <div className="containerForm">
  //           <h1 className="formularHead">Termin vereinbaren</h1>
  //           <h2>
  //             Aufgrund von Wartungsarbeiten sind im Moment leider keine
  //             Terminbuchungen möglich.
  //           </h2>
  //           <h2> Wir sind in Kürze wieder für Sie da.</h2>
  //         </div>
  //       </div>
  //     </main>
  //     <footer className="footer">
  //       <ul className="footer__list">
  //         <li className="footer__list-item">
  //           <a
  //             className="footer__link"
  //             href="https://www.stadtwerke-witten.de/impressum"
  //             target="_blank"
  //             rel="noreferrer"
  //           >
  //             Impressum
  //           </a>
  //         </li>
  //         <li className="footer__list-item">
  //           <a
  //             className="footer__link"
  //             href="https://www.stadtwerke-witten.de/datenschutz"
  //             target="_blank"
  //             rel="noreferrer"
  //           >
  //             Datenschutz
  //           </a>
  //         </li>
  //       </ul>
  //     </footer>
  //   </div>
  // );
  return (
    <div className="containerAussen">
      <header className="header">
        <img
          src={`${process.env.PUBLIC_URL ?? ""}/SW_witten.svg`}
          alt="logo"
        ></img>
      </header>
      <div className="container">
          <div className="containerForm">
            <h1 className="formularHead">Termin vereinbaren</h1>
            <div>
            <strong>Sie möchten einen Termin im Kundenbüro der Stadtwerke Witten vereinbaren?</strong>
              <br />Aktuell arbeiten wir ohne Terminvergabe. Besuchen Sie uns während unserer Servicezeiten.
            </div>
            <br />
            <br />
            <div>
              Kundenzentrum Impuls
              <br />Hauptstraße 7
              <br />58452 Witten 
            </div>
            <br />
            <br />
            <div>
              <strong>Öffnungszeiten:</strong>
              <br />Mo.
              <br />8.00 - 13.00 und 14.00 - 17.00 Uhr
              <br /><br />Di. bis Do.
              <br />8.00 - 13.00 und 14.00 - 16.00 Uhr
              <br /><br />Fr. 
              <br />8.00 - 13.00 Uhr
              <br /><br />Sa. 
              <br />8.00 - 12.00 Uhr
            </div>
          </div>
      </div>
      <footer className="footer">
        <ul className="footer__list">
          <li className="footer__list-item">
            <a
              className="footer__link"
              href="https://www.stadtwerke-witten.de/impressum"
              target="_blank"
              rel="noreferrer"
            >
              Impressum
            </a>
          </li>
          <li className="footer__list-item">
            <a
              className="footer__link"
              href="https://www.stadtwerke-witten.de/datenschutz"
              target="_blank"
              rel="noreferrer"
            >
              Datenschutz
            </a>
          </li>
        </ul>
      </footer>
    </div>
  )

  return (
    <div className="containerAussen">
      <header className="header">
        <img
          src={`${process.env.PUBLIC_URL ?? ""}/SW_witten.svg`}
          alt="logo"
        ></img>
      </header>
      {error && (
        <ErrorMessage message={"Ein Fehler ist aufgetreten!"}></ErrorMessage>
      )}
      {isLoading || isIdle || isError ? (
        <div className="container">
          <div className="containerForm">
            <h1 className="formularHead">Termin vereinbaren</h1>
            <Formik
              initialValues={{
                salutation: 1,
                firstname: "",
                lastname: "",
                street: "",
                place: "",
                plz: "",
                email: "",
                number: "",
                issue: "",
                meet_type: "",
                location: locations.length === 1 ? locations[0].id : "",
                concern: "",
                date: "",
                time: "",
                reachability: "",
                checked: false,
              }}
              enableReinitialize
              validationSchema={DisplayingErrorMessagesSchema}
              onSubmit={async (values) => {
                setSubmitStatus("loading");
                const timeanddate =
                  dayjs(values.date, "DD.MM.YYYY").format("YYYY-MM-DD") +
                  "T" +
                  values.time;
                const response = await fetch(
                  `${process.env.REACT_APP_API_URL}/appointments/`,
                  {
                    headers: {
                      accept: "application/json",
                      "content-type": "application/json",
                    },
                    method: "post",
                    body: JSON.stringify({
                      customer: {
                        salutation: values.salutation,
                        first_name: values.firstname,
                        last_name: values.lastname,
                        street: values.street,
                        place: values.place,
                        zip_code: values.plz,
                        phone_number: values.number,
                        email: values.email,
                        reachability: values.reachability,
                      },
                      meet_type: values.meet_type,
                      location: values.location,
                      start: timeanddate,
                      concern: values.concern,
                      concern_custom_text: values.issue,
                    }),
                  }
                );

                const data = await response.json();
                if (!response.ok) {
                  setSubmitStatus("error");
                  const error = (data && data.message) || response.status;
                } else {
                  setUserData(() => data);
                  setSubmitStatus("success");
                }
              }}
            >
              {({ errors, touched, values }) => (
                <Form className="form">
                  <h2 className="form-headline" style={{ marginTop: 0 }}>
                    Wie können wir Ihnen helfen? (1 / 3)
                  </h2>
                  {locations.length > 1 ? (
                    <DropdownSelect
                      items={locations}
                      name="location"
                      label="das Servicecenter"
                      onSelectedItemChange={(selectedItem) => {
                        loadDate(selectedItem, values.concern);
                      }}
                      required
                    />
                  ) : null}
                  <DropdownSelect
                    items={concerns}
                    name="concern"
                    label="Ihr Anliegen"
                    onSelectedItemChange={(selectedItem) => {
                      loadDate(selectedItem, values.location);
                    }}
                    disabled={concerns.length <= 0}
                    required
                  />

                  <DropdownSelect
                    name="meet_type"
                    items={meet_type}
                    label="die Art des Termins:"
                    required
                  />
                  <CSSTransition
                    in={
                      !(
                        values.location === "" ||
                        values.concern === "" ||
                        values.meet_type === ""
                      )
                    }
                    classNames="fade"
                    timeout={duration}
                  >
                    <div className="fade-container">
                      <h2 className="form-headline">
                        Wählen Sie Ihren Wunschtermin (2/3)
                      </h2>
                      {!loadingDates ? (
                        <>
                          <DropdownSelect
                            items={Object.keys(dates).map((d) => ({
                              id: d,
                              name: d,
                            }))}
                            name="date"
                            label="ein Datum"
                            key="index"
                            onSelectedItemChange={(selectedItem) => {
                              setDate(selectedItem);
                            }}
                            required
                            disabled={!Boolean(values.concern)}
                          />
                          <DropdownSelect
                            items={
                              date
                                ? dates[date]
                                    .map((d) => ({ id: d, name: d }))
                                    .reduce((acc, cur) => {
                                      const index = acc.findIndex((item) => {
                                        return item.id === cur.id;
                                      });
                                      if (index === -1) {
                                        return [...acc, cur];
                                      }
                                      return acc;
                                    }, [])
                                : []
                            }
                            name="time"
                            label="eine Uhrzeit"
                            disabled={!Boolean(values.date)}
                            required
                          />
                        </>
                      ) : (
                        <p className="loader">
                          <img src={loader} alt="" />
                          Lade freie Termine...
                        </p>
                      )}
                      <label htmlFor="issue">Was können wir für Sie tun?</label>
                      <Field
                        as="textarea"
                        name="issue"
                        placeholder="Ihr Anliegen"
                        className={
                          touched.issue && errors.issue
                            ? "textareaerror"
                            : "textarea"
                        }
                      />
                      {touched.issue && errors.issue && (
                        <p className="errorMessageTextarea">{errors.issue}</p>
                      )}
                    </div>
                  </CSSTransition>
                  <CSSTransition
                    in={!(values.date === "" || values.time === "")}
                    classNames="fade"
                    timeout={duration}
                  >
                    <div className="fade-container">
                      <h2 className="form-headline">Ihre Daten (3 / 3)</h2>
                      <DropdownSelect
                        name="salutation"
                        items={salutation}
                        label="Ihre Anrede"
                      />
                      <MyInputField
                        label="Vorname"
                        name="firstname"
                        type="text"
                        placeholder="Ihr Vorname"
                        required
                      />

                      <MyInputField
                        label="Nachname"
                        name="lastname"
                        type="text"
                        placeholder="Ihr Nachname"
                        required
                      />

                      <MyInputField
                        label="Straße"
                        name="street"
                        type="text"
                        placeholder="Ihre Straße und Hausnummer"
                      />

                      <MyInputField
                        label="Ort"
                        name="place"
                        type="text"
                        placeholder="Ihre Stadt"
                      />

                      <MyInputField
                        label="PLZ"
                        name="plz"
                        type="text"
                        placeholder="Ihre Postleitzahl"
                      />

                      <MyInputField
                        label="Email"
                        name="email"
                        type="email"
                        placeholder="Ihre Email-Adresse"
                        required
                      />

                      <MyInputField
                        label="Telefon"
                        name="number"
                        type="text"
                        placeholder="Ihre Telefonnummer"
                      />

                      <div className="container__checkbox">
                        <div className="container__checkbox_inner">
                          <label className="label">
                            <Field
                              type="checkbox"
                              className="checkbox"
                              name="checked"
                            />
                            Ich bin damit einverstanden, dass die E-Mail-Adresse
                            zu Zwecken der Terminverarbeitung und der Zusendung
                            einer Bestätigung verwendet wird. Sollte ich keine
                            E-Mail-Adresse angeben, wird mir eine Druckansicht
                            der Bestätigung angezeigt.
                          </label>
                        </div>
                        {touched.checked && errors.checked && (
                          <p className="errorMessageCheckbox">
                            {errors.checked}
                          </p>
                        )}
                      </div>
                      <div className="button-container">
                        <Buttons
                          className="large small"
                          type="submit"
                          content="Termin buchen"
                          disabled={isLoading}
                        ></Buttons>
                      </div>
                    </div>
                  </CSSTransition>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      ) : null}
      {isSuccess && userData ? (
        <>
          <div className="main">
            <div className="container_daten_alle">
              <h1>Vielen Dank für Ihre Terminbuchung!</h1>
              <div className="container_daten">
                <p>
                  Wir haben Ihnen wunschgemäß an unserem Standort{" "}
                  <strong>{userData.location.name}</strong> einen persönlichen
                  Beratungstermin für Ihr Anliegen{" "}
                  <strong>{userData.concern.name}</strong> reserviert.
                </p>
                <h2>Ihre persönlichen Daten:</h2>
                <>
                  {userData.customer.first_name && (
                    <p>
                      <strong>Vorname:</strong> {userData.customer.first_name}
                    </p>
                  )}
                  {userData.customer.last_name && (
                    <p>
                      <strong>Nachname:</strong> {userData.customer.last_name}
                    </p>
                  )}
                  {userData.customer.street && (
                    <p>
                      <strong>Straße:</strong> {userData.customer.street}
                    </p>
                  )}
                  {userData.customer.zip_code && (
                    <p>
                      <strong>PLZ:</strong> {userData.customer.zip_code}
                    </p>
                  )}
                  {userData.customer.place && (
                    <p>
                      <strong>Stadt:</strong> {userData.customer.place}
                    </p>
                  )}
                  {userData.customer.email && (
                    <p>
                      <strong>Email:</strong> {userData.customer.email}
                    </p>
                  )}
                  {userData.customer.phone_number && (
                    <p>
                      <strong>Telefonnummer:</strong>{" "}
                      {userData.customer.phone_number}
                    </p>
                  )}
                  {userData.customer.reachability && (
                    <p>
                      <strong>Erreichbarkeit:</strong>{" "}
                      {userData.customer.reachability}
                    </p>
                  )}
                </>
                <>
                  <h2>Ihr ausgewählter Termin:</h2>
                  {userData.meet_type && (
                    <p>
                      <strong>Art des Termins:</strong>{" "}
                      {userData.meet_type &&
                      meet_type.findIndex(
                        (type) => type.id === userData.meet_type
                      ) !== -1
                        ? meet_type[
                            meet_type.findIndex(
                              (type) => type.id === userData.meet_type
                            )
                          ].name
                        : ""}
                    </p>
                  )}
                  {userData.location.name && (
                    <p>
                      <strong>Kundencenter:</strong> {userData.location.name}
                    </p>
                  )}
                  {userData.concern_custom_text && (
                    <p>
                      <strong>Anliegen:</strong> {userData.concern_custom_text}
                    </p>
                  )}
                  {userData.concern.name && (
                    <p>
                      <strong>Gewähltes Anliegen:</strong>{" "}
                      {userData.concern.name}
                    </p>
                  )}
                  {userData.start && (
                    <p>
                      <strong>Datum:</strong>{" "}
                      {dayjs(userData.start).format("DD.MM.YYYY")}
                    </p>
                  )}
                  {userData.start && (
                    <p>
                      <strong>Uhrzeit:</strong>{" "}
                      {dayjs(userData.start).format("HH:mm")}
                    </p>
                  )}
                </>
                <p className="subtitle">
                  Wir wünschen Ihnen bis dahin alles Gute und bitte bleiben Sie
                  gesund!
                </p>
                <p className="subtitle">Wir freuen uns auf Ihren Besuch!</p>
              </div>
            </div>
          </div>
        </>
      ) : null}
      <footer className="footer">
        <ul className="footer__list">
          <li className="footer__list-item">
            <a
              className="footer__link"
              href="https://www.stadtwerke-witten.de/impressum"
              target="_blank"
              rel="noreferrer"
            >
              Impressum
            </a>
          </li>
          <li className="footer__list-item">
            <a
              className="footer__link"
              href="https://www.stadtwerke-witten.de/datenschutz"
              target="_blank"
              rel="noreferrer"
            >
              Datenschutz
            </a>
          </li>
        </ul>
      </footer>
    </div>
  );
};

export default Formular;
