import "./App.css";
import Formular from "./Components/Formular/Formular";
import "../src/styles/styles.scss";
import { React } from "react";
import { MyContextProvider } from "./Components/MyContext";

function App() {
  return (
    <MyContextProvider>
      <Formular />
    </MyContextProvider>
  );
}

export default App;
