import React from "react";

function ErrorMessage(props) {
  return (
    <div className="error_container">
      <h1 className="error_text">{props.message}</h1>
    </div>
  );
}

export default ErrorMessage;
